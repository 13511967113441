const birthDate = new Date().getFullYear() - 2002;
export const data = [
	{
		id: 1,
		name: 'Name',
		value: 'Harish K Shetty',
	},
	{
		id: 3,
		name: 'Email',
		value: 'harishkshetty7@gmail.com',
	},
	{
		id: 4,
		name: 'Phone',
		value: '+65 88926844',
	},
	{
		id: 5,
		name: 'Country',
		value: 'Singapore',
	},
];
